@media (max-width: 991px) {
  #categories_block_left {
    display: none; } }

#categories_block_left ul {
  list-style: none;
  margin: 0;
  padding: 0; }

#categories_block_left li {
  position: relative;
  margin: 0;
  padding: 0; }
  #categories_block_left li a {
    color: #888;
    display: block;
    font-size: 13px;
    line-height: 30px;
    padding: 0 30px 0 5px;
    border-bottom: 1px solid #eee; }
  #categories_block_left li span.grower {
    display: block;
    background: #f6f6f6;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-family: "FontAwesome";
    font-size: 14px; }
    #categories_block_left li span.grower.OPEN:before, #categories_block_left li span.grower.CLOSE:before {
      content: "\f068";
      display: block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      color: #888;
      line-height: 30px;
      text-align: center; }
    #categories_block_left li span.grower.CLOSE:before {
      content: "\f067";
      color: silver; }
  #categories_block_left li span.grower:hover + a,
  #categories_block_left li a:hover,
  #categories_block_left li a.selected {
    background: #f6f6f6; }
  #categories_block_left li li a {
    font-weight: normal;
    color: #777777; }
    #categories_block_left li li a:before {
      content: "\f105";
      font-family: "FontAwesome";
      line-height: 29px;
      padding-right: 6px; }

#categories_block_left .categories > li > ul > li > a {
  padding: 0 30px 0 12px; }

#categories_block_left .categories > li > ul > li > ul > li > a {
  padding: 0 30px 0 25px; }

.category_footer ul {
  margin: 0 -15px; }

.category_footer li {
  padding: 0 15px;
  width: 100%; }

@media (min-width: 768px) {
  .category_footer li {
    float: left;
    width: 50%; } }
